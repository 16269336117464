/* footer START */
.overlay-footer-image {
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  position: relative;
}

.overlay-footer-image > img {
  position: absolute;
  top: 0;
  width: 1100px;
  z-index: 1;
  opacity: .1;
}

footer{
  background-color: #001d4c;
  display: flex;
  justify-content: center;
}

.footer-content {
  color: #fff;
  display: flex;
  z-index: 500;
}

.form-footer-company-info { 
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.05);
  padding: 50px 37px;
  max-width: 350px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-footer-company-info > h2 {
  margin-bottom: 15px;
  font-size: 30px;
}

.form-footer-company-info > form {
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  padding: 5px;
  max-width: 300px;
  width: 100%;
  margin-top: 20px;
}

.form-footer-company-info > form > input {
  border: none;
  outline: none;
  padding: 10px 15px;
  font-size: 15px;
  width: 280px;
}

.form-footer-company-info > form > button {
  border: none;
  background-color: #001d4c;
  color: #fff;
  border-radius: 50%;
  padding: 12px 13px;
}

.footer-content > ul {
  display: flex;
  width: 100%;
  list-style-type: none;
  justify-content: space-between;
}

.footer-content > ul > li > h2 {
  font-weight: 500;
}

.footer-links {
  max-width: 200px;
  width: 100%;
}

.footer-links > ul {
  padding: 0;
  list-style-type: none;
  padding-top: 20px;
}

.footer-links > ul > li {
  padding: 10px 0;
}

.footer-links > ul > li > a{
  color: #c2d1e2;
  text-decoration: none;
  font-weight: 500;
  transition: .3s;
}

.footer-links > ul > li > a:hover{
  color: #ffffff;
}

.addressing-footer {
  max-width: 380px;
}
.addressing-footer > ul {
  padding-top: 20px;
  list-style-type: none;
}

.addressing-footer > ul > li {
  padding-top: 10px;
}

.addressing-footer > ul > li > h4 {
  font-weight: 500;
}

.addressing-footer > ul > .email > p{
  cursor: pointer;
  text-decoration: underline;
}

.addressing-footer > ul > li > p {
  color: #c2d1e2;
  padding-top: 10px;
}
/* footer END */

/* footer copyright START */

.copyright-container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #001d4c;
  display: flex;
  justify-content: center;
}

.copyright-container-content > ul{
  color: #fff;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 20px 0;
  align-items: center;
}

.copyright-container-content > ul > li {
  font-size: 15px;
}

.footer-link-buttons {
  display: flex;
}

.footer-link-buttons > a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: .3s;
  font-size: 15px;
  text-transform: uppercase;
}

.footer-link-buttons > a:hover {
  color: rgb(205, 205, 205);
}

/* footer copyright END */