.slider-below-header {
  height: 700px;
  display: flex;
  justify-content: center;
  background-color: #f3f7fd;
}

.slider-below-header-content {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.slider-info-left {
  max-width: 550px;
}

.slider-info-left > h3 {
  color: #0e2b3d;
  font-weight: normal;
  line-height: 1.2;
  font-size: 25px;
  margin-bottom: 20px;
}

.slider-info-left > h1 {
  font-size: 60px;
  font-weight: 400;
  color: #0e2b3d;
  line-height: 1.2;
  margin-bottom: 20px;
}

.slider-info-left > p {
  color: #595959;
  line-height: 28px;
  margin-bottom: 45px;
}

.slider-info-left > a {
  background-color: #086AD8;
  color: #fff;
  padding: 15px 30px;
  border-radius: 50px;
  display: flex;
  width: 180px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: .3s;
  font-weight: 500;
  text-decoration: none;
}

.slider-info-left > a > i {
  font-size: 20px;
}

.slider-info-left > a:hover {
  background-color: #034189;
}

.bold-primary-color {
  color: #086AD8;
  font-weight: 600;
}

.slider-image {
  margin-top: 100px;
}



/* about home page START */

.about-home {
  display: flex;
  justify-content: center;
}

.about-home-content {
  display: flex;
  justify-content: space-between;
  padding: 100px 0;
}

.images-home-about-section {
  display: flex;
  flex-wrap: wrap;
  width: 1000px;
  position: relative;
}

.image-one {
  position: relative;
}

.image-one > img {
  border-radius: 5px;
}

.absolute-box-image-about {
  position: absolute;
  top: 230px;
  left: 100px;
}

.absolute-box-image-about > img {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 10px #cccccc;
  padding: 5px;
  background: #ffffff;
}

.experience-about-home-box {
  position: absolute;
  right: 0;
  top: 40px;
  height: 200px;
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  transform: translate(50px, -50%);
  border-radius: 8px;
  box-shadow: 0 0 10px #cccccc;
}

.experience-about-home-box > h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 35px;
  color: #262626;
}

.text-home-about-section {
  max-width: 500px;
  width: 100%;
}

.text-home-about-section > h1{
  line-height: 40px;
  margin-bottom: 20px;
}

.text-home-about-section > p {
  color: #595959;
  line-height: 30px;
}

.text-home-about-section > ul {
  list-style-type: none;
  margin-bottom: 30px;
}

.text-home-about-section > ul > li {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
}

.text-home-about-section > ul > li > div {
  margin-left: 20px;
}

.text-home-about-section > ul > li > div > h3 {
  font-size: 25px;
  font-weight: 500;
}

.text-home-about-section > ul > li > div > p {
  margin-top: 10px;
  line-height: 30px;
  color: #595959;
}

.text-home-about-section > ul > li > svg {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin-top: -15px;
}

.read-more-button-home-page {
  cursor: pointer;
  transition: .3s;
  color: #086AD8;
  font-size: 18px;
  text-decoration: none;
}

.read-more-button-home-page:hover{
  color: #262626;
}
/* about home page END */

/* service offset box START */

.offset-service-container {
  background-color: #f3f7fd;
  display: flex;
  justify-content: center;
}

.background-image-overlay {
  padding: 100px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.offset-service-container-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-holder-offset-server {
  max-width: 500px;
  width: 100%;
}

.text-holder-offset-server > h3 {
  font-weight: 400;
  font-size: 25px;
  color: #262626;
  padding-bottom: 10px;
}

.text-holder-offset-server > h1 {
  line-height: 40px;
  padding-bottom: 20px;
  font-weight: 550;
  color: #262626;
}

.text-holder-offset-server > p {
  color: #595959;
  text-align: justify;
  line-height: 30px;
}

.text-holder-offset-server > div {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.text-holder-offset-server > div > a {
  background-color: #086AD8;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 22px;
  font-size: 20px;
  margin-right: 20px;
  transition: .3s;
  cursor: pointer;
}

.text-holder-offset-server > div > a:hover {
  background-color: #034189;
}

.text-holder-offset-server > div > p {
  color: #086AD8;
  font-weight: 500;
  font-size: 17px;
}

.service-holder-home-page {
  display: flex;
}

.service-holder-home-page > ul {
  list-style-type: none;
}

.service-holder-home-page > ul > li {
  padding: 20px ;
  background-color: #fff;
  max-width: 280px;
  padding: 50px 20px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 10px;
  cursor: pointer;
} 

.service-holder-home-page > ul > li > h2 {
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
}

.non-offset-service-box {
  margin-right: 30px;
}

.non-offset-service-box > li:first-child, .offset-service-box > li:last-child {
  background: linear-gradient( 90deg, rgba(8,106,216,1) 0%, rgba(10,60,116,1) 100%);
  color: #fff !important;
}

.offset-service-box {
  margin-right: -10px;
  margin-top: -50px;
}

.service-holder-home-page > ul > li:hover {
  background: linear-gradient( 90deg, rgba(8,106,216,1) 0%, rgba(10,60,116,1) 100%);
  color: #fff !important;
}

/* service offset box END */


.steps-boxes-home {
  display: flex;
  justify-content: center;
}

.steps-boxes-home-content {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.steps-boxes-home-content > h3 {
  font-size: 25px;
  font-weight: 700;
  color: #086AD8;
}

.steps-boxes-home-content > h1 {
  font-weight: 550;
}

.steps-boxes-home-content > div {
  border: 1px solid #086AD8;
  max-width: 80px;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}

.steps-boxes-home-content > ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  gap: 20px;
  padding: 50px 0;
  padding-bottom: 0;
}

.steps-boxes-home-content > ul > li {
  max-width: 300px;
  width: 100%;
  border: 1px solid #e7e7e7;
  padding: 50px 30px;
  background: #ffffff;
  position: relative;
  z-index: 1;
  transition: .3s;
  overflow: hidden;
  cursor: pointer;
}

.services-page-width-fix > ul > li {
  max-width: 100%;
}

.services-page-width-fix > ul > li > a {
  color: #086AD8;
  text-decoration: none;
  font-size: 1.1rem;
  transition: .3s;
}

.services-page-width-fix > ul > li > a:hover {
  color: #034189
}


.steps-boxes-home-content > ul > li:hover {
  border: 1px solid #086AD8;
}

.steps-boxes-home-content > ul > li > h2 {
  font-weight: 560;
  font-size: 20px;
  margin-bottom: 20px;
}

.steps-boxes-home-content > ul > li > p {
  color: #595959;
  line-height: 30px;
  margin-bottom: 20px;
}

.company-logos { 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f3f7fd;
  padding: 60px 0;
} 

.company-logos > h1 {
  font-weight: 600;
}

.company-logos > div { 
  border: 1px solid #086AD8;
  max-width: 80px;
  width: 100%;
  padding: 0;
  margin-top: 20px;
}

.company-logos > ul {
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  margin-top: 40px;
}

.inside-box-desgine {
  color: #cccccc;
  position: absolute;
  z-index: -10;
  font-size: 6rem;
  opacity: 0.3;
}