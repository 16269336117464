html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.content-sizing {
  max-width: 1240px;
  width: 100%;
}

.line {
  padding: 8px 0;
  border-right: 1px solid;
  border-color: rgb(247, 247, 247);
  margin: 0px 15px;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #086AD8;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


.loading-an-center {
  border: 1px solid;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}

.lds-facebook {
  position: absolute;
  top: 50%;
  bottom: 50%;
}