
.pages-title-path-holder {
  display: flex;
  justify-content: center;
  background-attachment: fixed !important;
  background-position: center center !important;
  background-size: cover !important;
}

.pages-title-path-holder > div {
  background-color: rgba(0,0, 0, 0.5);
  width: 100%;
  display: flex;
  padding: 80px 0;
  justify-content: center;
  color: #fff;
}

.pages-title-path-holder-content > ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pages-title-path-holder-content > ul > li > h1{
  font-weight: 400;
}

.pages-title-path-holder-content > ul > li > h3{
  font-weight: 400;
  background-color: rgba(0,0, 0, 0.5);
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-color-aero {
  color: #086AD8;
  font-weight: 600;
  font-size: 30px;
  padding: 0px 10px;
}

.primary-color-text {
  color: #086AD8;
  font-weight: 500;
}

.Terms-condition-form {
  border: 1px solid;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #e7e7e7;
  padding: 50px 30px;
  line-height: 30px;
}

.Terms-condition-form > ul {
  margin-left: 30px;
}

.contact-col {
  display: flex;
  justify-content: center;
}

.left-col {
  border: 1px solid #e7e7e7;
  max-width: 40%;
  width: 100%;
  padding: 60px 50px;
}

.left-col > span {
  color: #086AD8;
  font-weight: 600;
}


.left-col > h2 {
  line-height: 1.3em;
  font-size: 2rem;
  color: #000000;
  margin: 0px 30px 10px 0px; 
  margin-top: 10px; 
}

.contact-col-info {
  display: flex;
  padding: 80px 0;
  justify-content: space-between;
}

.contact-info-form-col {
  padding: 15px 0;
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.contact-icon {
  border: 1px solid #e7e7e7;
  display: flex;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  color: #086AD8;
  border-radius: 50%;
}

.contact-info-form-col > div > h3 {
  font-weight: 500;
}

.contact-info-form-col > div > p {
  color: #686868;
}

.contact-icon > i {
  font-size: 1.3rem;
}

.form-contact {
  width: 100%;
  padding: 50px 80px;
  padding-right: 0;
}

.form-contact > span {
  color: #086AD8;
  font-weight: 600;
}

.form-contact > h2 {
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.col-2-filed {
  padding: 10px 0;
  display: flex;
  gap: 10px;
  width: 100%;
}

.col-2-filed > input {
  width: 50%;
  padding: 20px 20px 20px 20px;
  border-radius: 3px;
  border-style: solid;
  border-width: 0px 0px 0px 0px;
  background-color: #F6F7F9;
  font-size: 1rem;
}

.form-contact > textarea {
  padding: 20px 20px 20px 20px;
  border-radius: 5px 5px 5px 5px;
  border-style: solid;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  border-width: 0px 0px 0px 0px;
  font-size: 1rem;
  margin-top: 5px;
  background-color: #F6F7F9;
}

.form-contact > button {
  background-color: #086AD8;
  color: #fff;
  margin-top: 10px;
  border: none;
  font-size: 1rem;
  transition: .3s;
  cursor: pointer;
  padding: 15px 30px;
}

.form-contact > button:hover {
  background-color: #034189;
}